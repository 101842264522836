export const carouselImages = [
  {
    id: 1,
    img: "/teamImages/first_row/Group_1614.webp",
    alt: "team1",
    url: "https://londonknights.com/",
  },
  {
    id: 2,
    img: "/teamImages/first_row/Group_1615.webp",
    alt: "team2",
    url: "https://lse.lps.org/",
  },
  {
    id: 3,
    img: "/teamImages/first_row/Group_1616.webp",
    alt: "team3",
    url: "https://bbhs.palmbeachschools.org/Athletics",
  },
  {
    id: 4,
    img: "/teamImages/first_row/Group_1617.webp",
    alt: "team4",
    url: "https://www.midlandathletics.com/",
  },
  {
    id: 5,
    img: "/teamImages/first_row/Group_1623.webp",
    alt: "team5",
    url: "https://www.elkhornweb.org/enhs/",
  },
  {
    id: 6,
    img: "/teamImages/first_row/Group_1614.webp",
    alt: "team1",
    url: "https://londonknights.com/",
  },
  {
    id: 7,
    img: "/teamImages/first_row/Group_1615.webp",
    alt: "team2",
    url: "https://lse.lps.org/",
  },
  {
    id: 8,
    img: "/teamImages/first_row/Group_1616.webp",
    alt: "team3",
    url: "https://bbhs.palmbeachschools.org/Athletics",
  },
  {
    id: 9,
    img: "/teamImages/first_row/Group_1617.webp",
    alt: "team4",
    url: "https://www.midlandathletics.com/",
  },
  {
    id: 10,
    img: "/teamImages/first_row/Group_1623.webp",
    alt: "team5",
    url: "https://www.elkhornweb.org/enhs/",
  },
]

export const carouselImagesRight = [
  {
    id: 1,
    img: "/teamImages/second_row/Group_1621.webp",
    alt: "team1",
    url: "https://omavs.com/sports/mens-basketball/roster",
  },
  {
    id: 2,
    img: "/teamImages/second_row/Group_1619.webp",
    alt: "team2",
    url: " https://www.roedeers.se/",
  },
  {
    id: 3,
    img: "/teamImages/first_row/UO_desktop.webp",
    alt: "team3",
    url: "https://www.unionomaha.com/",
  },
  {
    id: 4,
    img: "/teamImages/second_row/Group_1618.webp",
    alt: "team4",
    url: "https://mustangactivities.org/",
  },
  {
    id: 5,
    img: "/teamImages/second_row/Group_1622.webp",
    alt: "team5",
    url: "https://www.fremontsoccer.org/",
  },
  {
    id: 6,
    img: "/teamImages/second_row/Group_1621.webp",
    alt: "team1",
    url: "https://omavs.com/sports/mens-basketball/roster",
  },
  {
    id: 7,
    img: "/teamImages/second_row/Group_1619.webp",
    alt: "team2",
    url: " https://www.roedeers.se/",
  },
  {
    id: 8,
    img: "/teamImages/second_row/Group_1620.webp",
    alt: "team3",
    url: "https://www.unionomaha.com/",
  },
  {
    id: 9,
    img: "/teamImages/second_row/Group_1618.webp",
    alt: "team4",
    url: "https://mustangactivities.org/",
  },
  {
    id: 10,
    img: "/teamImages/second_row/Group_1622.webp",
    alt: "team5",
    url: "https://www.fremontsoccer.org/",
  },
]
