import Battery from "../../assets/progress/battery.svg"
import Health from "../../assets/progress/health.svg"
import Wheel from "../../assets/progress/wheel.svg"

export const leftTeamData = [
  {
    id: 1,
    alt: "health",
    attribute: 100,
    iconImage: Health,
    value: 100,
  },
  {
    id: 2,
    alt: "wheel",
    attribute: 22,
    iconImage: Wheel,
    value: 22,
  },
  {
    id: 3,
    alt: "battery",
    attribute: 60,
    iconImage: Battery,
    value: 60,
  },
]

export const rightTeamData = [
  {
    id: 1,
    alt: "health",
    attribute: 80,
    iconImage: Health,
    value: 80,
  },
  {
    id: 2,
    alt: "wheel",
    attribute: 32,
    iconImage: Wheel,
    value: 32,
  },
  {
    id: 3,
    alt: "battery",
    attribute: 40,
    iconImage: Battery,
    value: 40,
  },
]
