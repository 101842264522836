import React from "react"
import ScrollToTop from "../components/ScrollToTop"
import Signin from "../components/signin"

const SignInPage = () => {
  return (
    <div>
      <ScrollToTop />
      <Signin />
    </div>
  )
}

export default SignInPage
