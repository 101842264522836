import styled from "styled-components"

export const SlideImg = styled.img`
  width: 300px;
  height: 300px;

  @media screen and (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`
