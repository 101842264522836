import SvgMPlace from "../../assets/svgMPlace.svg"

export const navbarLinks = [
  {
    id: 1,
    name: "Marketplace",
    url: "https://nftdev.yourlife.io/nftMarket",
    image: SvgMPlace,
  },
  {
    id: 2,
    name: "Coach's Room",
    url: "https://nftdev.yourlife.io/coach",
  },
  {
    id: 3,
    name: "Game",
    url: "https://nftdev.yourlife.io/game",
  },
  {
    id: 4,
    name: "Teams",
    url: "https://nftdev.yourlife.io/teams",
  },
  {
    id: 5,
    name: "Start Playing",
    url: "https://nftdev.yourlife.io/authenticate",
    authenticate: true,
  },
  {
    id: 6,
    name: "HELP",
    url: " https://docs.yourlife.io",
  },
]
