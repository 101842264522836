export const journeyData = [
  {
    id: 1,
    date: "JUN 2022",
    title: "First members",
    description:
      "Sign first agreements with athletes and start to work with secure area",
    green: true,
  },
  {
    id: 2,
    date: "EARLY JUL 2022",
    title: "Mint athletes’ NFT and setup it to marketplace",
    description:
      "Public mint is open and our community issues their own NFTs and start to sell its and earn money from invitations and loyalty program",
    green: true,
  },
  {
    id: 3,
    date: "Late JUL 2022",
    title: "First matches",
    description: "Free-to-play version to learn with the platform",
  },
  {
    id: 4,
    date: "Sep 2022",
    title: "start first championship",
    description: "Try to earn much money with championship and betting",
  },
  {
    id: 5,
    date: "dec 2022",
    title: "public representation",
    description: "We will start big show for people and community is growing",
  },
  {
    id: 6,
    date: "2023",
    title: "launch building",
    description:
      "If you want to play and earn, but don’t want to take part in matches - create your own gym and develop it!",
  },
]
